import { useStoreState } from "easy-peasy";

const translations = {
    EN: {
        AllTimePerformance: "All-time performance",
        ActualPrice: "Actual Price",
        AmbientCompliance: "Ambient compliance",
        AnalyzeButton: "Analyze",
        AnalyzingButton: "Analyzing",
        AnalysisResults: "Analysis Result",
        AvailabilityCompliance: "Availability compliance",
        Best: "Best",
        Better: "Better",
        Card1Title: "Select a Planogram Recipe",
        Card2Description: "Please ensure to have a clear view of the entire shelf or display area.",
        Card2Title: "Capture entire fridge based on the ROI box",
        Card3Description: "Please select and capture the POSM materials. You can upload up to 3 images.",
        Card3Title: "POSM Materials Check",
        CaptureText: "Capture",
        Captured: "Captured",
        Chart: "Chart",
        ChillerCloseText: "Chiller (Door Close)",
        ChillerDepthText: "Chiller (Depth)",
        ChillerCompliance: "Chiller compliance",
        ChillerModalButton: "Continue",
        ChillerModalInput: "Chiller Type",
        ChillerModalText: "Please select the chiller type that you are going to capture.",
        ChillerOpenText: "Chiller (Door Open)",
        CompetetiveBI: "Competitive BI",
        CompFound: "Competitor found",
        ContinueCapture: "Continue Capture",
        Detected: "Detected",
        DetectedPrice: "Detected Price",
        DiscardModalContinue: "Continue capture",
        DiscardModalLeave: "Leave",
        DiscardModelTitle: "Discard Content?",
        DiscardModelTitleText: "If you leave now, your photos will not be saved.",
        Done: "Done",
        EnergyDrink: "Energy Drink",
        Fail: "Fail",
        Facing: "Facing",
        FacingCompliance: "Facing compliance (Horizontal)",
        Found: "Found",
        Good: "Good",
        HeaderTopBanner: "Please click on the camera button to capture display in different areas.",
        HelpButtonText: "Capture Guide",
        HelpText: "Need help with capture?",
        ImageCancel: "Cancel",
        ImageCancelText: "If you leave now, your photos will not be saved.",
        ImageCancelTitle: "Discard Content?",
        ImageText: "Image",
        ImageUploadStep1Text: "Please open the chiller to capture the entire shelf or display area.",
        ImageUploadStep2Text: "Please close the chiller to capture the entire shelf or display area.",
        ImageUploadStep3Text: "Please capture all of the POSM inside and outside of the chiller.",
        JobResults: "Job results",
        Leave: "Leave",
        MineralWater: "Mineral Water",
        NoneCompliance: "None Compliance",
        NoOfShelving: "No of shelving",
        NotFound: "Not Found",
        Opportunity: "Opportunity",
        Poor: "Poor",
        Preview: "Preview",
        Price: "Price",
        PriceTagInclude: "Include price tags",
        Pricing: "Pricing Detection",
        Product: "Product",
        Recapture: "Recapture",
        Report: "Report",
        SaveAndContinue: "Save and Continue",
        Share: "Share",
        ShareofShelf: "Share of Shelf",
        ShelvingCompliance: "Shelving compliance",
        SKUDistOpp: "SKU Distribution Opportunity",
        UnverfiedText: "Unverified",
        VerifiedText: "Verified",
        View: 'View',
        ViewCapture: "View Details",
        PosterModalText: "Please select the poster type that you are going to capture.",
        PosterText: "Ambient",
        Planogram: "Planogram",
        PlanogramCompliance: "Planogram Compliance",
        Submit: "Submit",
    },
    MY: {
        AllTimePerformance: "Prestasi Sepanjang Masa",
        ActualPrice: "Harga Sebenar",
        AmbientCompliance: "Pematuhan Suhu Ambien",
        AnalyzeButton: "Analisis",
        AnalyzingButton: "Sedang Menganalisis",
        AnalysisResults: "Keputusan Analisis",
        AvailabilityCompliance: "Pematuhan Ketersediaan",
        Best: "Terbaik",
        Better: "Lebih Baik",
        Card1Title: "Pilih Resepi Planogram",
        Card2Description: "Pastikan anda mempunyai pandangan jelas terhadap keseluruhan rak atau kawasan paparan.",
        Card2Title: "Tangkap keseluruhan peti sejuk berdasarkan kotak ROI",
        Card3Description: "Sila pilih dan tangkap bahan-bahan POSM. Anda boleh memuat naik sehingga 3 imej.",
        Card3Title: "Semakan Bahan POSM",
        CaptureText: "Tangkap",
        Captured: "Ditangkap",
        Chart: "Carta",
        ChillerCloseText: "Pintu peti sejuk ditutup",
        ChillerDepthText: "Chiller (Depth)",
        ChillerCompliance: "Pematuhan Peti Sejuk",
        ChillerModalButton: "Teruskan",
        ChillerModalInput: "Jenis Peti Sejuk",
        ChillerModalText: "Sila pilih jenis peti sejuk yang akan anda tangkap.",
        ChillerOpenText: "Pintu peti sejuk dibuka",
        CompetetiveBI: "BI Kompetitif",
        CompFound: "Pesaing dijumpai",
        ContinueCapture: "Teruskan Tangkapan",
        Detected: "Dikesan",
        DetectedPrice: " Harga Dikesan",
        DiscardModalContinue: "Teruskan tangkapan",
        DiscardModalLeave: "Keluar",
        DiscardModelTitle: "Buang Kandungan?",
        DiscardModelTitleText: "Jika anda keluar sekarang, foto anda tidak akan disimpan.",
        Done: "Selesai",
        EnergyDrink: "Minuman Tenaga",
        Fail: "Gagal",
        Facing: "Hadapan",
        FacingCompliance: "Pematuhan Hadapan (Mendatar)",
        Found: "Dijumpai",
        Good: "Baik",
        HeaderTopBanner: "Sila klik butang kamera untuk menangkap paparan di kawasan yang berbeza.",
        HelpButtonText: "Panduan Tangkapan",
        HelpText: "Perlu bantuan dengan tangkapan?",
        ImageCancel: "Batal",
        ImageCancelText: "Jika anda keluar sekarang, foto anda tidak akan disimpan.",
        ImageCancelTitle: "Buang Kandungan?",
        ImageText: "Imej",
        ImageUploadStep1Text: "Sila buka peti sejuk untuk menangkap keseluruhan rak atau kawasan paparan.",
        ImageUploadStep2Text: "Sila tutup peti sejuk untuk menangkap keseluruhan rak atau kawasan paparan.",
        ImageUploadStep3Text: "Sila tangkap semua POSM di dalam dan di luar peti sejuk.",
        JobResults: "Keputusan Tugasan",
        Leave: "Keluar",
        MineralWater: "Air Mineral",
        NoneCompliance: "Tiada Pematuhan",
        NoOfShelving: "Bilangan Rak",
        NotFound: "Tidak Dijumpai",
        Opportunity: "Peluang",
        Poor: "Lemah",
        Preview: "Pratonton",
        Price: "Harga",
        PriceTagInclude: "Sertakan tanda harga",
        Pricing: "Perbandingan Harga",
        Product: "Produk",
        Recapture: "Tangkap Semula",
        Report: "Report",
        SaveAndContinue: "Simpan dan Teruskan",
        Share: "Kongsi",
        ShareofShelf: "Kongsi Rak",
        ShelvingCompliance: "Pematuhan Rak",
        SKUDistOpp: "Peluang Pengagihan SKU",
        UnverfiedText: "Tidak Disahkan",
        VerifiedText: "Disahkan",
        View: "Lihat",
        ViewCapture: "Lihat Tangkapan",
        PosterModalText: "Sila pilih jenis poster yang anda akan tangkap.",
        PosterText: "Ambien",
        Planogram: "Planogram",
        PlanogramCompliance: "Planogram Compliance",
        Submit: "Submit",
    }
};

const Locale = () => {
    const language = useStoreState(state => state.locale);
    return translations[language] || translations.EN;
};

export default Locale;