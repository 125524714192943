import React, { useState, useRef, useEffect } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import { Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "@mui/material/Modal";
import Locale from "../../../locale";
import useStyles from "./cameraModalStyles";
import CameraIcon from "../../../Assets/Icons/Camera.png";
import HelpIcon from "./../../../Assets/Icons/Help.png";
import CaptureGuideModal from "../CaptureGuide/CapGuide";

import CaptureImage from "../../../Assets/Images/CaptureImage.jpg";

const useDimensions = (aspectRatio = 4 / 3) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const maxWidth = window.innerWidth; // Maximum available width
      const maxHeight = window.innerHeight * 0.75; // Maximum available height (80% of viewport height)
      let width = maxWidth;
      let height = width / aspectRatio;

      // Adjust dimensions if height exceeds the maximum height
      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      }

      setDimensions({ width, height });
    };

    calculateDimensions(); // Initial calculation
    window.addEventListener("resize", calculateDimensions); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateDimensions); // Cleanup
  }, [aspectRatio]);

  return dimensions;
};

const ConfirmationModal = ({ open, onClose, locale, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <div
          className={classes.container}
          style={{
            outline: "none",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "30rem",
            backgroundColor: "white",
            borderRadius: "1rem",
            margin: "0 auto",
            padding: "1.5rem",
            height: "auto",
          }}
        >
          <div className={classes.header} style={{ marginBottom: "1.2rem" }}>
            <div className={classes.title}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                {locale.ImageCancelTitle}
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              variant="bodySmall"
              style={{ color: theme.palette.neutral[700], textAlign: "left" }}
            >
              {locale.ImageCancelText}
            </Typography>
          </div>
          <div
            className={classes.buttonContainer}
            style={{ justifyContent: "flex-end", margin: "1rem 0 0" }}
          >
            <Button
              className={classes.leaveModalButton1}
              variant="outlined"
              onClick={() => {
                handleClose();
                onClose();
              }}
            >
              <Typography variant="titleSmall">{locale.Leave}</Typography>
            </Button>

            <Button
              className={classes.leaveModalButton2}
              variant="contained"
              onClick={handleClose}
            >
              <Typography variant="titleSmall">
                {locale.ContinueCapture}
              </Typography>
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const CameraModal2 = ({ open, onClose, modalTitle, picIndex }) => {
  const locale = Locale();
  const images = useStoreState((state) => state.images);
  const setImage = useStoreActions((actions) => actions.addPosmImage);
  const removeImage = useStoreActions((actions) => actions.removePosmImage);
  const classes = useStyles();

  const [closeModal, setCloseModal] = useState(false);
  const [captureModalOpen, setCaptureModalOpen] = useState(false);

  const [fileInputRef, setFileInputRef] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(3 / 4);
  const dimensions = useDimensions(aspectRatio);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result; // Base64 string

        // Create a new Image object to read dimensions
        const img = new Image();
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;
          const aspectRatio = originalWidth / originalHeight;
          const targetWidth = 1152;
          const targetHeight = 1536;
          const targetAspectRatio = targetWidth / targetHeight;

          // Create a canvas to adjust the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to target dimensions
          canvas.width = targetWidth;
          canvas.height = targetHeight;

          // Calculate padding to maintain aspect ratio
          let drawWidth, drawHeight, offsetX, offsetY;
          if (aspectRatio > targetAspectRatio) {
            // Image is wider than target aspect ratio
            drawWidth = targetWidth;
            drawHeight = targetWidth / aspectRatio;
            offsetX = 0;
            offsetY = (targetHeight - drawHeight) / 2;
          } else {
            // Image is taller than target aspect ratio
            drawWidth = targetHeight * aspectRatio;
            drawHeight = targetHeight;
            offsetX = (targetWidth - drawWidth) / 2;
            offsetY = 0;
          }

          // Fill canvas with grey background
          ctx.fillStyle = "grey";
          ctx.fillRect(0, 0, targetWidth, targetHeight);

          // Draw the image on the canvas with calculated padding
          ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

          // Convert the canvas content to Base64
          const finalBase64Image = canvas.toDataURL("image/jpeg");

          // Update the aspect ratio (3:4) and save the image as Base64 in the store
          setAspectRatio(targetAspectRatio);
          setImage({
            index: picIndex[0],
            index2: picIndex[1],
            index3: picIndex[2],
            value: finalBase64Image,
          });
          console.log("cm2 set image");
        };

        img.src = base64Image; // Set image source to the Base64 string
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const openCamera = () => {
    if (fileInputRef) {
      fileInputRef.click(); // Programmatically trigger the input click
    }
  };
  // Automatically open camera when modal opens and no image is selected
  useEffect(() => {
    if (
      open &&
      !captureModalOpen &&
      !closeModal &&
      !images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]] &&
      fileInputRef
    ) {
      console.log("clicked");
      fileInputRef.click();
    }
  }, [
    open,
    captureModalOpen,
    closeModal,
    images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]],
    fileInputRef,
  ]);

  useEffect(() => {
    if (!images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]]) {
      setAspectRatio(3 / 4);
    }
  }, [images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]]]);

  return (
    <ThemeProvider theme={theme}>
      <CaptureGuideModal
        open={captureModalOpen}
        onClose={() => setCaptureModalOpen(false)}
      />
      <ConfirmationModal
        open={closeModal}
        onClose={onClose}
        locale={locale}
        handleClose={() => setCloseModal(false)}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div
                className={classes.cancelButton}
                onClick={() => setCloseModal(true)}
              >
                <Typography variant="titleSmall">
                  {locale.ImageCancel}
                </Typography>
              </div>
              <div className={classes.title}>
                <Typography variant="h3">{modalTitle}</Typography>
              </div>
              <div>
                <img
                  src={HelpIcon}
                  alt="Help Icon"
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    marginRight: "0.5rem",
                    zIndex: 1000,
                    position: "relative",
                  }}
                  onClick={() => setCaptureModalOpen(true)}
                />
              </div>
            </div>

            <center>
              <input
                ref={(ref) => setFileInputRef(ref)}
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hidden input
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${dimensions.width}px`, // Parent matches image width
                  height: `${dimensions.height}px`, // Parent matches image height
                  backgroundColor: "#f0f0f0",
                  overflow: "hidden", // Prevents overflow if the image exceeds container size
                }}
              >
                <img
                  style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                  }}
                  src={
                    images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]] ||
                    CaptureImage
                  }
                  alt="LastPhoto"
                />
              </div>
              <div className={classes.buttonContainer}>
                {!images?.[picIndex?.[0]]?.[picIndex?.[1]]?.[picIndex?.[2]] ? (
                  <Button
                    className={classes.postCameraButtons}
                    variant="outlined"
                    onClick={openCamera}
                  >
                    <img
                      alt="Capture"
                      src={CameraIcon}
                      style={{ marginRight: "0.75rem", width: "1.25rem" }}
                    />
                    <Typography variant="titleSmall">
                      {locale.CaptureText}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.postCameraButtons}
                    variant="outlined"
                    onClick={() => {
                      removeImage({
                        index: [picIndex[0]],
                        index2: [picIndex[1]],
                        index3: [picIndex[2]],
                      });
                    }}
                  >
                    <img
                      alt="Recapture"
                      src={CameraIcon}
                      style={{ marginRight: "0.75rem", width: "1.25rem" }}
                    />
                    <Typography variant="titleSmall">
                      {locale.Recapture}
                    </Typography>
                  </Button>
                )}

                <Button
                  sx={{
                    backgroundColor: `${theme.palette.primary[400]} !important`,
                    color: "white !important",
                  }}
                  className={classes.postCameraButtons}
                  variant="contained"
                  onClick={onClose}
                >
                  <Typography variant="titleSmall">
                    {locale.SaveAndContinue}
                  </Typography>
                </Button>
              </div>
            </center>
          </div>
        </Fade>
      </Dialog>
    </ThemeProvider>
  );
};

export default CameraModal2;
