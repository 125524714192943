import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  Typography,
  Tabs,
  Tab,
  Box,
  Divider,
  Button,
  Snackbar,
  Slide,
} from "@mui/material";
import theme from "../../../theme";
import useStyles from "./AnalysisStyles";
import CheckIcon from "../../../Assets/Icons/cicle-check.png";
import StarIcon from "../../../Assets/Icons/Star.png";
import Lightning from "../../../Assets/Icons/Lightning.png";
// import WaterIcon from "../../../Assets/Icons/water.png";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import CameraIcon from "../../../Assets/Icons/Camera.png";
import ViewCapture from "../../../Assets/Icons/view.png";
import CloseCircle from "../../../Assets/Icons/close-circle.png";
import Locale from "../../../locale";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import { globalStyles } from "../../../theme";
import { useStoreState, useStoreActions } from "easy-peasy";

import RestApi from "../../../Utils/Backend";

import ChillerModal from "../../../Components/Modals/Chiller/chillerModal";
import PosterModal from "../../../Components/Modals/Poster/posterModal";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

// import ChillerModal from "../../../Components/Modals/Chiller/chillerModal";

const replacePlaceholders = (template, replacements) => {
  const templateString = JSON.stringify(template);
  let filledTemplateString = templateString;

  for (const [key, value] of Object.entries(replacements)) {
    const placeholder = `{${key}}`;
    filledTemplateString = filledTemplateString.replaceAll(placeholder, value);
  }

  return JSON.parse(filledTemplateString);
};

const statusMap = {
  FAILED: 0,
  GOOD: 1,
  BETTER: 2,
  BEST: 3,
};

const valueToStatus = {
  0: "FAILED",
  1: "GOOD",
  2: "BETTER",
  3: "BEST",
};

const statusToShelve = {
  FAILED: 0,
  GOOD: 1,
  BETTER: 1.5,
  BEST: 2,
};

const statusToFacing = {
  FAILED: 0,
  GOOD: 7,
  BETTER: 11,
  BEST: 14,
};

const getChillerStatusOverall = (totalMarks) => {
  if (totalMarks < 90) return 0; // Failed
  if (totalMarks >= 90 && totalMarks < 95) return 1; // Good
  if (totalMarks >= 95 && totalMarks < 100) return 2; // Better
  if (totalMarks >= 100) return 3; // Best
};
// Define template with placeholders as strings
const tabDataTemp = [
  {
    label: "Red Bull",
    content: [
      {
        name: "Chiller",
        enable: "{chiller_enable}",
        status: "{chiller_status_overall}",
        items: [
          {
            name: "Products",
            type: "compare",
            total: "{chiller_sku_count_total}",
            pass: "{chiller_sku_count}",
            status: "{chiller_sku_status}",
          },
          {
            name: "No of shelving",
            type: "single",
            value: "{chiller_shelves}",
          },
          {
            name: "Planogram",
            type: "bool",
            value: "{chiller_planogram_match}",
          },
          {
            name: "POSM",
            type: "compare",
            total: "{chiller_posm_count_total}",
            pass: "{chiller_posm_count}",
            status: "{chiller_posm_status}",
          },
        ],
        noneCompliance: {
          status: "{chiller_nc_status}",
          items: "{chiller_nc_items}",
        },
      },

      {
        name: "Poster",
        enable: "{poster_enable}",
        status: "{poster_status}",
        items: [
          {
            name: "POSM",
            type: "compare",
            total: "{chiller_posm_count_total}",
            pass: "{poster_posm_count}",
            status: "{poster_posm_status}",
          },
        ],
        noneCompliance: {
          status: "{poster_nc_status}",
          items: "{poster_nc_items}",
        },
      },
    ],
  },
];

const chillerSkuDataTemp = {
  availabilityCompliance: {
    total: "{chiller_sku_count_total}",
    passes: "{chiller_sku_count}",
    units: [],
  },
  facingCompliance: {
    total: "{chiller_facing_count_total}",
    passes: "{chiller_facing_count}",
    detected: [],
    requirements: [
      // {
      //   change: "+",
      //   amount: 3,
      //   units: [
      //     "Bottle 150ml",
      //     "Gold 325ml",
      //     "Gold 250ml",
      //     "Less Sugar 250ml",
      //     "Plus 250ml",
      //     "Apple Muscat 250ml",
      //     "Pamelo 250ml",
      //   ],
      // },
    ],
  },
};

const chillerShelvingDataTemp = {
  status: "{chiller_shelve_status}",
  image: {
    roi: [],
  },
};

const chillerPlanogramDataTemp = {
  status: "{chiller_planogram_match}",
  image: {
    roi: [],
  },
};

const chillerPosmDataTemp = {
  name: "Chiller compliance",
  total: "{chiller_posm_count_total}",
  pass: "{chiller_posm_count}",
  status: "{chiller_posm_status}",
  units: [],
};

const posterPosmDataTemp = {
  name: "Poster compliance",
  total: "{poster_posm_count_total}",
  pass: "{poster_posm_count}",
  status: "{poster_posm_status}",
  units: [],
};

const commonDataTemp = {
  chiller_enable: false,
  chiller_status_overall: 0,
  chiller_sku_count_total: 0,
  chiller_sku_count: 0,
  chiller_sku_status: 0,
  chiller_sku_units: [],
  chiller_shelves: 0,
  chiller_shelve_status: 0,
  chiller_planogram_match: 0,
  chiller_posm_count_total: 0,
  chiller_posm_count: 0,
  chiller_posm_status: 0,
  chiller_posm_units: [],
  chiller_nc_status: 0,
  chiller_nc_items: "",
  chiller_facing_count_total: 0,
  chiller_facing_count: 0,
  chiller_facing_units: [],
  poster_enable: false,
  poster_status: 0,
  poster_posm_count_total: 0,
  poster_posm_count: 0,
  poster_posm_status: 0,
  poster_posm_units: [],
  poster_nc_status: 0,
  poster_nc_items: "",
};

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Analysis = () => {
  const navigate = useNavigate();
  const locale = Locale();
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const [value, setValue] = useState(0);

  const {
    sessionId,
    images,
    chillerPlanogramData,
    chillerPosmData,
    posterPosmData,
    chillerSkuLabels,
    chillerPosmLabels,
    posterPosmLabels,
    ylParams,
    actual_prices,
  } = useStoreState((state) => ({
    sessionId: state.sessionId,
    images: state.images,
    chillerPlanogramData: state.chillerPlanogramData,
    chillerPosmData: state.chillerPosmData,
    posterPosmData: state.posterPosmData,
    chillerSkuLabels: state.chillerSkuLabels,
    chillerPosmLabels: state.chillerPosmLabels,
    posterPosmLabels: state.posterPosmLabels,
    ylParams: state.ylParams,
    actual_prices: state.actual_prices,
  }));

  const { removeImage, resetAll, setAnalyzedData } = useStoreActions(
    (actions) => ({
      removeImage: actions.removeImage,
      resetAll: actions.resetAll,
      setAnalyzedData: actions.setAnalyzedData,
    })
  );

  const [chillerOverall, setChillerOverall] = useState("FAILED");
  const [posterOverall, setPosterOverall] = useState("FAILED");

  const [isChillerModalOpen, setIsChillerModalOpen] = useState(false);
  const [isPosterModalOpen, setIsPosterModalOpen] = useState(false);

  const openChillerModal = () => setIsChillerModalOpen(true);
  const closeChillerModal = () => setIsChillerModalOpen(false);

  const openPosterModal = () => setIsPosterModalOpen(true);
  const closePosterModal = () => setIsPosterModalOpen(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const [status, setStatus] = useState(""); // "success" or "error"
  const [message, setMessage] = useState("");

  const [tabData, setTabData] = useState(() =>
    replacePlaceholders(tabDataTemp, commonDataTemp)
  );

  useEffect(() => {
    console.log("tabData: ", tabData);
    console.log("posm: ", statusMap[chillerPosmData?.match]);
    console.log("match: ", statusMap[chillerPosmData?.match] || 0);
    // statusMap[posterPosmData?.status] || 0
  }, [tabData]);

  useEffect(() => {
    const chillerSkuRedBullImages = (chillerSkuLabels || []).filter(
      (image) => image?.brand === "Red Bull"
    );
    const chillerSkuRedBullIClassIdSet = new Set(
      (chillerSkuRedBullImages || [])
        .map((image) => image?.class_id)
        .filter(Boolean)
    );
    const chillerSkuDetectionClassIds = chillerPlanogramData?.class_ids || [];
    const tempFacing = chillerPlanogramData?.facing || [];

    const chillerSkuAvailabilitySet = new Set(
      chillerSkuDetectionClassIds.filter((id) =>
        chillerSkuRedBullIClassIdSet.has(id)
      )
    );

    const chillerSkuComplianceTotal = chillerSkuRedBullIClassIdSet.size;
    const chillerSkuCompliancePasses = chillerSkuAvailabilitySet.size;

    const chillerSkuClassIdCounts = chillerSkuDetectionClassIds.reduce(
      (acc, id) => {
        if (chillerSkuRedBullIClassIdSet.has(id)) {
          acc[id] = (acc[id] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    const chillerSkuUnits = chillerSkuRedBullImages.map((image) => ({
      id: image?.class_id ?? "-1", // Use class_id or a default value
      name: image?.product_name ?? "Unknown", // Use product_name or default
      full_name: image?.product_name ?? "Unknown",
      img: image?.image_url ?? "", // Use image_url or empty string
      availability: chillerSkuDetectionClassIds.includes(image?.class_id)
        ? 1
        : 0, // Set availability based on class_ids
      count: chillerSkuClassIdCounts[image?.class_id ?? "-1"] ?? 0,
    }));

    const chillerFacingDetectionClassIds = tempFacing.map((value) =>
      value > 100 ? value - 100 : value
    );

    const chillerFacingClassIdCounts = chillerFacingDetectionClassIds.reduce(
      (acc, id) => {
        if (chillerSkuRedBullIClassIdSet.has(id)) {
          acc[id] = (acc[id] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    const chillerFacingUnits = Object.keys(chillerFacingClassIdCounts).map(
      (id) => {
        const unit = chillerSkuUnits.find((u) => u.id === Number(id));
        return {
          name: unit ? unit.full_name : `Unknown`,
          count: chillerFacingClassIdCounts[id],
        };
      }
    );

    const chillerPosmRedBullImages = chillerPosmLabels.filter(
      (image) => image?.brand === "Red Bull"
    );

    const chillerPosmRedBullClassIdSet = new Set(
      chillerPosmRedBullImages.map((image) => image?.class_id).filter(Boolean)
    );

    const chillerPosmDetectionClassIds = chillerPosmData?.class_ids ?? [];

    const chillerPosmAvailabilitySet = new Set(
      chillerPosmDetectionClassIds.filter((id) =>
        chillerPosmRedBullClassIdSet.has(id)
      )
    );

    const chillerPosmComplianceTotal = chillerPosmRedBullClassIdSet.size;
    const chillerPosmCompliancePasses = chillerPosmAvailabilitySet.size;

    const chillerPosmUnits = chillerPosmRedBullImages.map((image) => ({
      id: image?.class_id ?? "Unknown", // Use class_id or a default value
      name: image?.product_name ?? "Unknown Product", // Use product_name or default
      img: image?.image_url ?? "", // Use image_url or empty string
      availability: chillerPosmDetectionClassIds.includes(image?.class_id)
        ? 1
        : 0, // Set availability based on class_ids
    }));

    //Poster Calculation
    const posterPosmRdBullImages = posterPosmLabels.filter(
      (image) => image?.brand === "Red Bull"
    );

    const posterPosmRedBullClassIdSet = new Set(
      posterPosmRdBullImages.map((image) => image?.class_id).filter(Boolean)
    );

    const posterPosmDetectionClassIds = posterPosmData?.class_ids ?? [];

    const posterPosmAvailabilitySet = new Set(
      posterPosmDetectionClassIds.filter((id) =>
        posterPosmRedBullClassIdSet.has(id)
      )
    );

    const posterPosmComplianceTotal = posterPosmRedBullClassIdSet.size;
    const posterPosmCompliancePasses = posterPosmAvailabilitySet.size;

    const posterPosmUnits = posterPosmRdBullImages.map((image) => ({
      id: image?.class_id ?? "Unknown", // Use class_id or a default value
      name: image?.product_name ?? "Unknown Product", // Use product_name or default
      img: image?.image_url ?? "", // Use image_url or empty string
      availability: posterPosmDetectionClassIds.includes(image?.class_id)
        ? 1
        : 0, // Set availability based on class_ids
    }));

    const product_status =
      chillerSkuCompliancePasses > 5 &&
      [1, 2, 6, 7].every((id) => chillerSkuAvailabilitySet.has(id));

    const shelving_status = statusMap[chillerPlanogramData?.match];
    const posm_status = statusMap[chillerPosmData?.match];

    var total_marks = 0;
    if (product_status && shelving_status > 0 && posm_status > 0)
      total_marks += 40;
    if (product_status) total_marks += 20;
    if (posm_status > 0) total_marks += 20;

    if (shelving_status == 1) total_marks += 10;
    else if (shelving_status == 2) total_marks += 15;
    else if (shelving_status == 3) total_marks += 20;

    setChillerOverall(valueToStatus[getChillerStatusOverall(total_marks)]);
    setPosterOverall(valueToStatus[statusMap[posterPosmData?.match] || 0]);

    const productIssue = product_status == false ? "SKU" : null;
    const shelvingIssue =
      chillerPlanogramData?.match === "FAILED" ? "Shelving" : null;
    const posmIssue = chillerPosmData?.match === "FAILED" ? "POSM" : null;
    const posterPosmIssue = posterPosmData?.match === "FAILED" ? "POSM" : null;

    // console.log("product_status: ", product_status);
    // console.log("shelving_status: ", shelving_status);
    // console.log("posm_status: ", posm_status);
    // console.log("Total Marks: ", total_marks);

    const commonData = {
      ...commonDataTemp,
      chiller_enable: images[0][0] != null && images[0][1] != null,
      chiller_status_overall: getChillerStatusOverall(total_marks),
      chiller_sku_count_total: chillerSkuComplianceTotal,
      chiller_sku_count: chillerSkuCompliancePasses,
      chiller_sku_status: product_status ? 1 : 0,
      // chiller_sku_units: chillerSkuUnits,
      chiller_shelves: statusToShelve[chillerPlanogramData?.match] || 0,
      chiller_shelve_status: statusMap[chillerPlanogramData?.match] || 0,
      chiller_planogram_match:
        chillerPlanogramData?.match_planogram?.length > 0 &&
        chillerPlanogramData.match_planogram.every((el) => el === 1)
          ? 1
          : 0,
      chiller_posm_count_total: chillerPosmComplianceTotal,
      chiller_posm_count: chillerPosmCompliancePasses,
      chiller_posm_status: statusMap[chillerPosmData?.match] || 0,
      // chiller_posm_units: chillerPosmUnits,
      chiller_nc_status:
        (product_status == false ? 0 : 1) &&
        (chillerPlanogramData?.match === "FAILED" ? 0 : 1) &&
        (chillerPosmData?.match === "FAILED" ? 0 : 1),

      chiller_nc_items: [productIssue, shelvingIssue, posmIssue]
        .filter(Boolean)
        .join(", "),
      chiller_facing_count_total:
        statusToFacing[chillerPlanogramData?.match] || 0,
      chiller_facing_count:
        chillerFacingUnits?.reduce((total, unit) => total + unit.count, 0) || 0,

      poster_enable:
        Array.isArray(images[3][1]) &&
        images[3][1].some((item) => item !== null),
      poster_status: statusMap[posterPosmData?.match] || 0,
      poster_posm_count_total: posterPosmComplianceTotal,
      poster_posm_count: posterPosmCompliancePasses,
      poster_posm_status: statusMap[posterPosmData?.match] || 0,
      // poster_posm_units: posterPosmUnits,
      poster_nc_status: posterPosmData?.match === "FAILED" ? 0 : 1,
      poster_nc_items: [posterPosmIssue],
    };

    const tabdata = replacePlaceholders(tabDataTemp, commonData);
    setTabData(tabdata);

    setAnalyzedData({
      stock: "Chiller",
      type: "SKU",
      value: {
        ...replacePlaceholders(chillerSkuDataTemp, commonData),
        availabilityCompliance: {
          ...replacePlaceholders(chillerSkuDataTemp, commonData)
            .availabilityCompliance,
          units: chillerSkuUnits,
        },
        facingCompliance: {
          ...replacePlaceholders(chillerSkuDataTemp, commonData)
            .facingCompliance,
          detected: chillerFacingUnits,
        },
      },
    });

    setAnalyzedData({
      stock: "Chiller",
      type: "Shelving",
      value: replacePlaceholders(chillerShelvingDataTemp, commonData),
    });

    setAnalyzedData({
      stock: "Chiller",
      type: "Planogram",
      value: replacePlaceholders(chillerPlanogramDataTemp, commonData),
    });

    setAnalyzedData({
      stock: "Chiller",
      type: "POSM",
      value: {
        ...replacePlaceholders(
          replacePlaceholders(chillerPosmDataTemp, commonData),
          commonData
        ),
        units: chillerPosmUnits,
      },
    });

    setAnalyzedData({
      stock: "Poster",
      type: "POSM",
      value: {
        ...replacePlaceholders(
          replacePlaceholders(posterPosmDataTemp, commonData),
          commonData
        ),
        units: posterPosmUnits,
      },
    });
  }, [
    chillerPlanogramData,
    chillerPosmData,
    posterPosmData,
    chillerSkuLabels,
    chillerPosmLabels,
    posterPosmLabels,
  ]); // Added dependencies here

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  const handleSubmit = () => {
    // Send the updated parameters and reset the state
    const status = RestApi().sendResults(sessionId, ylParams, actual_prices);
    if (status) {
      setStatus("success"); // or "error"
      setMessage("Data Sent successfully!");
    } else {
      setStatus("error"); // or "error"
      setMessage("Data Sent Failed!");
    }
    setPopupOpen(false); // Reset the state first
    setTimeout(() => {
      setPopupOpen(true); // Reopen the Snackbar
    }, 5); // Short delay to allow state reset

    // Navigate to "/" after 2 seconds
    setTimeout(() => {
      setPopupOpen(false); // Close the popup
      resetAll();
      navigate("/");
    }, 1000);
  };

  const handleReport = () => {
    // Send the updated parameters and reset the state
    const status = RestApi().reportSession(sessionId);
    if (status) {
      setStatus("success"); // or "error"
      setMessage("Session Reported successfully!");
    } else {
      setStatus("error"); // or "error"
      setMessage("Session Reported Failed!");
    }
    setPopupOpen(false); // Reset the state first
    setTimeout(() => {
      setPopupOpen(true); // Reopen the Snackbar
    }, 5); // Short delay to allow state reset
  };

  return (
    <ThemeProvider theme={theme}>
      <ChillerModal open={isChillerModalOpen} onClose={closeChillerModal} />
      <PosterModal open={isPosterModalOpen} onClose={closePosterModal} />

      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Ensures space between left and right-aligned elements
              alignItems: "center",
              width: "100%", // Ensures the div takes up the full width
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.checkIconContainer}>
                <img
                  alt="check icon"
                  className={classes.checkIcon}
                  src={CheckIcon}
                />
              </div>
              <Typography variant="titleMedium" className={classes.subtitle}>
                {locale.JobResults}
              </Typography>
            </div>

            <Button
              sx={{ marginTop: "0.875rem !important" }}
              variant="contained"
              className={classes.reportButton}
              onClick={handleReport}
            >
              <Typography variant="bodySmall">{locale.Report}</Typography>
            </Button>
          </div>
          <Box sx={{ width: "100%", margin: "1rem 0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabData.map((tab, tabIndex1) => (
                <Tab
                  key={tabIndex1}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: `${theme.palette.primary[400]} !important`, // Selected tab color
                    },
                  }}
                  label={
                    <Typography variant="titleSmall" component="span">
                      {tab.label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <SwipeableViews
              axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleSwipeChange}
            >
              {tabData.map((tab, tabIndex2) => (
                <TabPanel value={value} index={tabIndex2} theme2={theme2}>
                  {tab.content.map(
                    (item, contentIndex) =>
                      item.enable === "true" ? ( // Ensure item.enable is true before rendering
                        <div key={contentIndex}>
                          <div className={classes.tabStatusContainer}>
                            <Typography
                              variant="titleMedium"
                              sx={{
                                color:
                                  item.status == 0
                                    ? theme.palette.error[300]
                                    : theme.palette.neutral[700],
                              }}
                            >
                              {item.name}
                            </Typography>
                            <div
                              className={
                                item.status == 0
                                  ? classes.tabStatus0
                                  : classes.tabStatus2
                              }
                            >
                              <Typography variant="caption">
                                {item.status == 0
                                  ? locale.Fail
                                  : item.status == 1
                                  ? locale.Good
                                  : item.status == 2
                                  ? locale.Better
                                  : locale.Best}
                              </Typography>
                            </div>
                          </div>
                          {/* Content items mapping */}
                          <div className={classes.tabItemContainer}>
                            {item.items.map((subItem, itemIndex) => (
                              <div key={itemIndex}>
                                <div className={classes.tabItem}>
                                  <Typography variant="bodySmall">
                                    {subItem.name}
                                  </Typography>
                                  <div>
                                    {subItem.type === "bool" && (
                                      <Typography variant="titleSmall">
                                        {subItem.value === 1 ? "Yes" : "No"}
                                      </Typography>
                                    )}
                                    {subItem.type === "single" && (
                                      <Typography variant="titleSmall">
                                        {subItem.value != undefined
                                          ? subItem.value
                                          : "0"}
                                      </Typography>
                                    )}
                                    {subItem.type === "compare" && (
                                      <>
                                        <Typography
                                          variant="titleSmall"
                                          style={{
                                            color:
                                              subItem.status == 0
                                                ? theme.palette.error[300]
                                                : theme.palette.neutral[500],
                                          }}
                                        >
                                          {subItem.pass}
                                        </Typography>
                                        /
                                        <Typography variant="titleSmall">
                                          {subItem.total}
                                        </Typography>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/* Sub items of the content item mapping */}
                                {subItem.items && (
                                  <div className={classes.tabSubItemContainer}>
                                    {subItem.items.map((nestedItem, index2) => (
                                      <div
                                        key={index2}
                                        className={classes.tabItem}
                                      >
                                        <Typography variant="bodySmall">
                                          {nestedItem.name}
                                        </Typography>
                                        <div>
                                          {nestedItem.type === "bool" && (
                                            <Typography variant="titleSmall">
                                              {nestedItem.value === 1
                                                ? "Yes"
                                                : "No"}
                                            </Typography>
                                          )}
                                          {nestedItem.type === "single" && (
                                            <Typography variant="titleSmall">
                                              {nestedItem.value}
                                            </Typography>
                                          )}
                                          {nestedItem.type === "compare" && (
                                            <>
                                              <Typography variant="titleSmall">
                                                {nestedItem.pass}
                                              </Typography>
                                              /
                                              <Typography variant="titleSmall">
                                                {nestedItem.total}
                                              </Typography>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {item.noneCompliance.status == 0 ? (
                            <div className={classes.noneComplianceContainer}>
                              <div className={globalstyles.redContainer}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={CloseCircle}
                                    alt="Close circle icon"
                                    className={classes.noneComplianceIcon}
                                  />
                                  <Typography
                                    variant="titleSmall"
                                    className={classes.noneComplianceTitle}
                                  >
                                    {locale.NoneCompliance}
                                  </Typography>
                                </div>
                                <Typography
                                  variant="bodySmall"
                                  className={classes.noneComplianceItems}
                                >
                                  {item.noneCompliance.items}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    navigate("/analysis/viewCapture", {
                                      state: { stock: item.name },
                                    })
                                  }
                                  variant="outlined"
                                  className={classes.recaptureButton}
                                  sx={{ bgcolor: "white" }}
                                >
                                  <Typography
                                    variant="bodySmall"
                                    style={{ marginRight: "0.5rem" }}
                                  >
                                    {locale.ViewCapture}
                                  </Typography>
                                  <img
                                    alt="View Capture icon"
                                    className={classes.checkIcon}
                                    src={ViewCapture}
                                  />
                                </Button>
                              </div>
                              <div>
                                <Button
                                  variant="contained"
                                  className={classes.noneComplianceRecapButton}
                                  onClick={() => {
                                    if (item.name === "Chiller") {
                                      openChillerModal();
                                    }
                                    if (item.name === "Poster") {
                                      openPosterModal();
                                    }
                                  }}
                                >
                                  <Typography variant="bodySmall">
                                    {locale.Recapture}
                                  </Typography>
                                  <img
                                    alt="Recapture icon"
                                    className={classes.recaptureIcon}
                                    src={CameraIcon}
                                  />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <Button
                              onClick={() =>
                                navigate("/analysis/viewCapture", {
                                  state: { stock: item.name },
                                })
                              }
                              variant="outlined"
                              className={classes.recaptureButton}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{
                                  marginRight: "0.5rem",
                                  color: theme.palette.primary[400],
                                }}
                              >
                                {locale.ViewCapture}
                              </Typography>
                              <img
                                alt="View Capture icon"
                                className={classes.checkIcon}
                                src={ViewCapture}
                              />
                            </Button>
                          )}
                          {contentIndex < tab.content.length - 1 && (
                            <Divider
                              sx={{
                                margin: "1.25rem 0",
                                bgcolor: theme.palette.neutral[100],
                              }}
                            />
                          )}
                        </div>
                      ) : null // Do not render anything if item.enable is false
                  )}
                </TabPanel>
              ))}
            </SwipeableViews>
          </Box>
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle
            title={locale.CompetetiveBI}
            icon={StarIcon}
            alt={locale.CompetetiveBI}
          />
          <div style={{ marginTop: "1rem" }}>
            <div
              className={classes.tabItemContainer}
              onClick={() => navigate("/analysis/singleItem")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="check icon"
                    className={classes.checkIcon}
                    src={Lightning}
                    style={{ marginRight: "0.5rem" }}
                  />

                  <Typography
                    variant="titleSmall"
                    className={classes.subtitle}
                    style={{ color: theme.palette.neutral[700] }}
                  >
                    {locale.EnergyDrink}
                  </Typography>
                </div>
                <ChevronRightIcon
                  sx={{
                    color: theme.palette.neutral[400],
                    transform: "scaleY(1.1)",
                  }}
                />
              </div>
            </div>
            {/* <div className={classes.tabItemContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="check icon"
                    className={classes.checkIcon}
                    src={WaterIcon}
                    style={{ marginRight: "0.5rem" }}
                  />

                  <Typography
                    variant="titleSmall"
                    className={classes.subtitle}
                    style={{ color: theme.palette.neutral[700] }}
                  >
                    {locale.MineralWater}
                  </Typography>
                </div>
                <ChevronRightIcon
                  sx={{
                    color: theme.palette.neutral[400],
                    transform: "scaleY(1.1)",
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
        {/* <Button
          sx={{ marginTop: "0.875rem !important" }}
          variant="contained"
          className={classes.noneComplianceRecapButton}
          onClick={() => {
            // Create a copy of ylParams and modify the status
            const updatedParams = {
              ...ylParams,
              status: chillerOverall,
              posmStatus: posterOverall,
            };

            // Send the updated parameters and reset the state
            RestApi().sendResults(sessionId, updatedParams);
            resetAll();
            navigate("/");
          }}
        >
          <Typography variant="bodySmall">{locale.Done}</Typography>
        </Button> */}

        <Button
          sx={{ marginTop: "0.875rem !important" }}
          variant="contained"
          className={classes.noneComplianceRecapButton}
          onClick={handleSubmit}
        >
          <Typography variant="bodySmall">{locale.Submit}</Typography>
        </Button>

        {/* Snackbar Popup */}
        <Snackbar
          open={popupOpen}
          onClose={() => setPopupOpen(false)} // Close the Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Center position
          TransitionComponent={(props) => <Slide {...props} direction="down" />} // Slide transition
          ContentProps={{
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: status === "success" ? "#4caf50" : "#f44336", // Green for success, Red for failure
              color: "#fff",
              padding: "1rem",
              borderRadius: "20px",
              maxWidth: "60vw",
            },
          }}
          message={
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              {status === "success" ? (
                <CheckCircleIcon fontSize="large" sx={{ color: "#fff" }} /> // Tick mark for success
              ) : (
                <ErrorIcon fontSize="large" sx={{ color: "#fff" }} /> // Cross mark for failure
              )}
              <Typography variant="bodySmall">{message}</Typography>
            </Box>
          }
          autoHideDuration={2000} // Automatically hide after 2 seconds
        />
      </div>
    </ThemeProvider>
  );
};

export default Analysis;
