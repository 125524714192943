import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1.25rem 0 0 !important",
    width: "100%",
    border: `1px solid ${theme.palette.primary[300]}`,
    borderRadius: "0.5rem !important",
    textTransform: "none !important",
    color: `${theme.palette.neutral[25]} !important`,
    background: `${theme.palette.primary[400]} !important`,
  },
  buttonIcon: {
    filter: "brightness(0) invert(1)",
    width: "1.25rem",
    height: "1.25rem",
    margin: "0.5rem",
  },

  button2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1.25rem 0 0 !important",
    width: "100%",
    border: `1px solid ${theme.palette.primary[300]}`,
    borderRadius: "0.5rem !important",
    textTransform: "none !important",
    color: `${theme.palette.neutral[25]} !important`,
    background: `${theme.palette.primary[400]} !important`,
  },
  buttonIcon2: {
    filter: "brightness(0) invert(1)",
    width: "1.25rem",
    height: "1.25rem",
    margin: "0.5rem",
  },
}));

export default useStyles;
