import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import theme from "../../../theme";
import { globalStyles } from "../../../theme";
import GoBackHeader from "../../../Components/GoBackHeader/GoBackHeader";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import BottleIcon from "../../../Assets/Icons/Bottle.png";
import useStyles from "./ViewCaptureStyles";
import ShelfIcon from "../../../Assets/Icons/Shelf.png";
import CouponIcon from "../../../Assets/Icons/coupon.png";
import CloseIcon from "../../../Assets/Icons/CloseRed.png";
import CheckIcon from "../../../Assets/Icons/check.png";
import AlertIcon from "../../../Assets/Icons/Alert.png";
import RedEmoji from "../../../Assets/Icons/RedEmoji.png";
import GreenEmoji from "../../../Assets/Icons/GreenEmoji.png";
import { useLocation } from "react-router-dom";
import { CheckCircle, Cancel } from "@mui/icons-material";

import { useStoreState, useStoreActions } from "easy-peasy";

import { generateAnnotatedImage } from "./ImageUtils";
import { PlanogramAnnotation } from "./PlanogramAnnotation";

import Locale from "../../../locale";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import MoneyIcon from "../../../Assets/Icons/Money.png";

const skuDataTemp = {
  availabilityCompliance: {
    total: 7,
    passes: 0,
    units: [],
  },
  facingCompliance: {
    total: 14,
    passes: 0,
    detected: [],
    requirements: [
      // {
      //   change: "+",
      //   amount: 3,
      //   units: [
      //     "Bottle 150ml",
      //     "Gold 325ml",
      //     "Gold 250ml",
      //     "Less Sugar 250ml",
      //     "Plus 250ml",
      //     "Apple Muscat 250ml",
      //     "Pamelo 250ml",
      //   ],
      // },
    ],
  },
};

const shelvingDataTemp = {
  status: 0,
  image: {
    roi: [],
  },
};

const POSMdataTemp = {
  name: "Chiller compliance",
  total: 3,
  passes: 0,
  units: [
    {
      id: 9,
      name: "RB Generic",
      availability: 0,
    },
    {
      id: 10,
      name: "Frame Generic",
      availability: 0,
    },
    {
      id: 11,
      name: "RBF Generic",
      availability: 0,
    },
    {
      id: 12,
      name: "RBGL Generic",
      availability: 0,
    },
    {
      id: 13,
      name: "RBGL Generic B",
      availability: 0,
    },
    {
      id: 14,
      name: " Thematic RBF TNG",
      availability: 0,
    },
  ],
};

// {
//   name: "Ambient compliance",
//   total: 3,
//   passed: 0,
//   units: [
//     {
//       id: 1,
//       name: "RB Generic",
//       availability: 0,
//     },
//     {
//       id: 2,
//       name: "RBF A",
//       availability: 0,
//     },
//     {
//       id: 3,
//       name: "RBF - Talent A",
//       availability: 0,
//     },
//     {
//       id: 4,
//       name: "RBF - Talent B",
//       availability: 0,
//     },
//     {
//       id: 5,
//       name: "RBF - Talent C",
//       availability: 0,
//     },
//     {
//       id: 6,
//       name: "RBGL Generic A",
//       availability: 0,
//     },
//     {
//       id: 7,
//       name: "RBGL Generic B",
//       availability: 0,
//     },
//     {
//       id: 8,
//       name: "Weekly Icon",
//       availability: 1,
//     },
//   ],
// },

const tabDataTemp = [
  {
    category: "Red Bull",
    content: [
      {
        product: "RB Bottle 150ml (RBB)",
        price: "N/A",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "RB Gold 250ml (RBG)",
        price: "N/A",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "RB Gold 325ml (RBGL)",
        price: "N/A",
        change_direction: "",
        change_percentage: 0,
      },
      {
        product: "RB Apple Muscat 250ml (RBFA)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "RB Pomelo 250ml (RBFP)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
      {
        product: "RB Less Sugar 250ml (RBX)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
      {
        product: "RB Plus 250ml (RBP)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
];

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const maxWidth = window.innerWidth * 0.82;
      const maxHeight = window.innerHeight * 0.8;
      let width = maxWidth;
      let height = (width * 4) / 3;

      if (height > maxHeight) {
        height = maxHeight;
        width = (height * 3) / 4;
      }

      setDimensions({ width, height });
      console.log({ width, height });
    };

    calculateDimensions();
    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, []);

  return dimensions;
};

const base64ImageConverter = (img) => {
  return `data:image/png;base64,${img}`;
};

const AvailabilityComponent = ({ unit, locale }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor:
          unit.availability === 1
            ? theme.palette.success[100]
            : theme.palette.error[100],
        borderRadius: "0.375rem",
        padding: "0 0.5rem",
        justifyContent: "center",
      }}
    >
      <img
        src={unit.availability === 1 ? CheckIcon : CloseIcon}
        style={{ maxWidth: "0.875rem", margin: "0.25rem", padding: "0.15rem" }}
        alt={unit.name}
      />
      <Typography
        variant="caption"
        style={{
          color:
            unit.availability === 1
              ? theme.palette.success[300]
              : theme.palette.error[300],
        }}
      >
        {unit.availability === 1 ? locale.Found : locale.NotFound}
      </Typography>
    </div>
  );
};

const AvailabilityIcon = ({ unit }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {unit.availability === 1 ? (
        <CheckCircle
          style={{
            color: theme.palette.success[200],
            width: "1.75rem",
            height: "1.75rem",
          }}
        />
      ) : (
        <Cancel
          style={{
            color: theme.palette.error[200],
            width: "1.75rem",
            height: "1.75rem",
          }}
        />
      )}
    </div>
  );
};

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ViewCapture = () => {
  const location = useLocation();
  const classes = useStyles();
  const globalstyles = globalStyles();
  const locale = Locale();
  const theme2 = useTheme();

  const { stock } = location.state || "";

  const {
    images,
    analyzedData,
    chillerPlanogramData,
    chillerPosmData,
    posterPosmData,
    chillerSkuLabels,
    actual_prices,
  } = useStoreState((state) => ({
    images: state.images,
    analyzedData: state.analyzedData,
    chillerPlanogramData: state.chillerPlanogramData,
    chillerPosmData: state.chillerPosmData,
    posterPosmData: state.posterPosmData,
    chillerSkuLabels: state.chillerSkuLabels,
    actual_prices: state.actual_prices,
  }));

  const addImage = useStoreActions((actions) => actions.addImage);
  const addPosmImage = useStoreActions((actions) => actions.addPosmImage);
  const setPrice = useStoreActions((actions) => actions.setPrice);

  const [annotatedImageSrc, setAnnotatedImageSrc] = useState(null);
  const [shelvingImageSrc, setShelvingImageSrc] = useState(null);
  const [planogramImageSrc, setPlanogramImageSrc] = useState(null);

  const [skuData, setSkuData] = useState(null);
  const [shelvingData, setShelvingData] = useState(null);
  const [planogramData, setPlanogramData] = useState(null);
  const [POSMdata, setPOSMdata] = useState(null);

  const [imgIndex, setImgIndex] = useState(0);

  const dimensions = useDimensions();

  const [tabData, setTabData] = useState(tabDataTemp);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (
      analyzedData[stock]["SKU"] &&
      Object.keys(analyzedData[stock]["SKU"]).length > 0
    ) {
      setSkuData(analyzedData[stock]["SKU"]);
    } else {
      setSkuData(null);
    }

    if (
      analyzedData[stock]["Shelving"] &&
      Object.keys(analyzedData[stock]["Shelving"]).length > 0
    ) {
      setShelvingData(analyzedData[stock]["Shelving"]);
    } else {
      setShelvingData(null);
    }

    if (
      analyzedData[stock]["Planogram"] &&
      Object.keys(analyzedData[stock]["Planogram"]).length > 0
    ) {
      setPlanogramData(analyzedData[stock]["Planogram"]);
    } else {
      setPlanogramData(null);
    }

    if (
      analyzedData[stock]["POSM"] &&
      Object.keys(analyzedData[stock]["POSM"]).length > 0
    ) {
      setPOSMdata(analyzedData[stock]["POSM"]);
    } else {
      setPOSMdata(null);
    }
  }, [analyzedData, stock]);

  useEffect(() => {
    console.log("SKU", skuData);
  }, [skuData]);

  useEffect(() => {
    console.log("Shelving", shelvingData);
  }, [shelvingData]);

  useEffect(() => {
    console.log("Planogram", planogramData);
  }, [planogramData]);

  useEffect(() => {
    console.log("POSM", POSMdata);
  }, [POSMdata]);

  useEffect(() => {
    console.log("imgIndex", imgIndex);
  }, [imgIndex]);

  useEffect(() => {
    async function convertImage() {
      console.log("Converting..");
      var planogramRawData = null;
      var posmRawData = null;
      var imageIndex = 0;

      if (stock == "Chiller") {
        planogramRawData = chillerPlanogramData;
        posmRawData = chillerPosmData;
        imageIndex = 0;
      } else if (stock == "Poster") {
        planogramRawData = null;
        posmRawData = posterPosmData;
        imageIndex = 3;
      }

      setImgIndex(imageIndex);

      try {
        const skuAnnotated = await generateAnnotatedImage(
          planogramRawData,
          images[imageIndex][0]
        );

        console.log("SKU Annotatted");

        setAnnotatedImageSrc(skuAnnotated);
        addImage({
          index: imageIndex,
          index2: 2,
          value: skuAnnotated,
        });

        console.log("SKU Saved");

        if (planogramRawData?.overlay_image != null) {
          setShelvingImageSrc(planogramRawData?.overlay_image);
          addImage({
            index: imageIndex,
            index2: 4,
            value: planogramRawData?.overlay_image,
          });
        }

        console.log("Shelved Saved");

        const planogramAnnotated = await PlanogramAnnotation(
          planogramRawData?.match,
          planogramRawData?.match_planogram
        );

        console.log("Planogram Genrated");

        setPlanogramImageSrc(planogramAnnotated);

        console.log("Planogram Saved");
      } catch (error) {
        console.error("Error generating annotated SKU image:", error);
      }

      try {
        for (let index = 0; index < posmRawData?.rois.length; index++) {
          if (images[imageIndex][1][index] != null) {
            const data = {
              rois: posmRawData?.rois[index],
              class_ids: posmRawData?.class_ids_each[index],
            };
            const posmAnnotated = await generateAnnotatedImage(
              data,
              images[imageIndex][1][index]
            );
            console.log("POSM Annotated");
            addPosmImage({
              index: imageIndex,
              index2: 3,
              index3: index,
              value: posmAnnotated,
            });
          }
        }
      } catch (error) {
        console.error("Error generating annotated POSM image:", error);
      }
    }
    convertImage();
  }, [chillerPlanogramData, chillerPosmData, posterPosmData, stock]);

  useEffect(() => {
    const chillerSkuRedBullImages = (chillerSkuLabels || []).filter(
      (image) => image?.brand === "Red Bull"
    );

    const price_list = chillerPosmData?.prices || [];

    // Update the tabDataTemp price field
    const updatedTabData = { ...tabDataTemp[0] }; // Shallow copy to avoid direct mutation
    updatedTabData.content = updatedTabData.content.map((item) => {
      // Find matching image for the product
      const image = chillerSkuRedBullImages.find(
        (img) => img.product_name === item.product
      );

      if (image) {
        const price = price_list[image?.class_id];

        // If price is not -1, update the price
        if (price !== -1) {
          return {
            ...item,
            price, // Update the price field
          };
        }
      }

      // Return the original item if no update is needed
      return item;
    });

    // Optionally log or set state with the updated data
    console.log("Updated Tab Data:", updatedTabData);

    // Example: Update a state
    setTabData([updatedTabData]);
  }, [chillerSkuLabels, chillerPosmData]);

  return (
    <ThemeProvider theme={theme}>
      <GoBackHeader
        text={`${locale.ViewCapture} (${stock})`} //Title of the page is loaded using the tabData
        location={"/analysis"}
      />
      {/* <center>
        <Typography variant="h2" className={classes.title}>
          {locale.AnalysisResults}
        </Typography>
      </center> */}
      <div className={globalstyles.mainContainer}>
        {skuData && (
          <div className={globalstyles.innerContainer}>
            <ContainerTitle
              title="SKU"
              icon={BottleIcon}
              images={[images[0][0], images[0][2], images[0][4]]}
            />
            {/* Availability Compliance Section */}
            <div className={classes.complianceContainer}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {locale.AvailabilityCompliance}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color:
                      skuData?.availabilityCompliance?.passes < 5
                        ? theme.palette.error[300]
                        : theme.palette.decorative.darkblue,
                  }}
                >
                  {skuData?.availabilityCompliance?.passes}
                </Typography>
                <Typography
                  variant="h1"
                  style={{ color: theme.palette.neutral[700] }}
                >
                  /{skuData?.availabilityCompliance?.total}
                </Typography>
              </div>
              <Grid container spacing={3} size={{ xs: 6, md: 6, lg: 4 }}>
                {Array.isArray(skuData?.availabilityCompliance?.units) &&
                  skuData.availabilityCompliance.units.map((unit, index) => (
                    <Grid
                      size={{ xs: 6, md: 6, lg: 4 }}
                      key={`skuData-availabilityCompliance-units-1-${index}`}
                    >
                      <Box
                        sx={{
                          backgroundColor:
                            unit.availability === 1
                              ? theme.palette.success[50]
                              : theme.palette.error[50],
                          padding: "0.5rem",
                          borderRadius: "0.375rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                          boxSizing: "border-box",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid size={{ xs: 8 }}>
                            <Grid container direction="column">
                              <img
                                src={unit.img}
                                alt="Unit Image"
                                style={{
                                  maxWidth: "5.5rem",
                                  height: "auto",
                                  borderRadius: "0.375rem",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid size={{ xs: 4 }}>
                            <AvailabilityIcon unit={unit} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid size={{ xs: 7 }}>
                            <Grid container direction="column">
                              <Typography
                                variant="titleSmall"
                                style={{
                                  color: theme.palette.neutral[700],
                                  wordWrap: "break-word",
                                }}
                              >
                                {unit.name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid size={{ xs: 5 }}>
                            <Typography
                              variant="titleSmall"
                              style={{
                                color: theme.palette.neutral[700],
                                wordWrap: "break-word",
                              }}
                            >
                              Qty {unit.count}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </div>
            <Divider
              sx={{
                margin: "1.25rem 0",
                bgcolor: theme.palette.neutral[100],
              }}
            />
            {/* Facing Compliance Section */}
            <div className={classes.complianceContainer}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {locale.FacingCompliance}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color:
                      skuData?.facingCompliance?.passes < 5
                        ? theme.palette.error[300]
                        : theme.palette.decorative.darkblue,
                  }}
                >
                  {skuData?.facingCompliance?.passes}
                </Typography>
                {/* <Typography
                  variant="h1"
                  style={{ color: theme.palette.neutral[700] }}
                >
                  /{skuData?.facingCompliance?.total}
                </Typography> */}
              </div>
              <div
                className={globalstyles.greyContainer}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.75rem",
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: theme.palette.neutral[400],
                  }}
                >
                  {locale.Detected}
                </Typography>
                {Array.isArray(skuData?.facingCompliance?.detected) &&
                  skuData.facingCompliance.detected.map((detected, idx) => (
                    <div
                      key={`detected-${idx}`}
                      style={{
                        color: theme.palette.neutral[700],
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Typography variant="bodySmall">
                        {detected.name}
                      </Typography>
                      <div>x {detected.count}</div>
                    </div>
                  ))}
              </div>
              {Array.isArray(skuData?.facingCompliance?.requirements) &&
                skuData.facingCompliance.requirements.map(
                  (requirement, index) => (
                    <div
                      className={globalstyles.redContainer}
                      style={{ marginBottom: 0 }}
                      key={`skuData-facingCompliance-requirements-${index}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={AlertIcon}
                          alt="Alert Icon"
                          className={globalstyles.alertIcon}
                        />
                        <Typography
                          variant="titleSmall"
                          style={{ color: theme.palette.error[300] }}
                        >
                          Please {requirement.change === "+" ? "add" : "remove"}{" "}
                          {requirement.amount} more items from below
                        </Typography>
                      </div>
                      <ul
                        style={{
                          margin: 0,
                          padding: "0 1.75rem",
                          color: theme.palette.error[400],
                        }}
                      >
                        {requirement.units.map((unit, idx) => (
                          <li
                            key={`requirement-unit-${idx}`}
                            style={{ marginTop: "0.5rem" }}
                          >
                            <Typography variant="bodySmall">{unit}</Typography>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
            </div>
          </div>
        )}

        {shelvingData && (
          <div className={globalstyles.innerContainer}>
            <ContainerTitle title={locale.NoOfShelving} icon={ShelfIcon} />
            <div className={classes.complianceContainer}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {locale.ShelvingCompliance}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  margin: "0.313rem 0",
                }}
              >
                <img
                  src={shelvingData?.status == 0 ? RedEmoji : GreenEmoji}
                  alt={
                    shelvingData?.status == 0
                      ? "Poor Compliance"
                      : "Good Compliance"
                  }
                  style={{ width: "2.5rem" }}
                />
                <Typography
                  variant="h3"
                  style={{
                    marginLeft: "0.5rem",
                    color:
                      shelvingData?.status == 0
                        ? theme.palette.error[300]
                        : theme.palette.success[300],
                  }}
                >
                  {shelvingData?.status == 0
                    ? locale.Poor + " (Less 1 Full Shelve)"
                    : shelvingData?.status == 1
                    ? locale.Good + " (1 Full Shelve)"
                    : shelvingData?.status == 2
                    ? locale.Better + " (1.5 Full Shelve)"
                    : locale.Best + " (2 Full Shelve)"}
                </Typography>
              </div>
              {annotatedImageSrc && dimensions && (
                <img
                  src={shelvingImageSrc}
                  alt="Shelf Compliance"
                  style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                    marginTop: "0.5rem",
                  }}
                />
              )}
              <div
                className={globalstyles.greyContainer}
                style={{ padding: "0.75rem" }}
              >
                <Typography
                  variant="caption"
                  style={{
                    color: theme.palette.neutral[400],
                    marginTop: "0.5rem",
                  }}
                >
                  {locale.ShelvingCompliance} {locale.Chart}
                </Typography>
                {[locale.Poor, locale.Good, locale.Better, locale.Best].map(
                  (level, index) => (
                    <div
                      key={`shelving-compliance-${index}`}
                      style={{
                        color: theme.palette.neutral[600],
                        marginTop: "0.5rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="bodySmall">{level}</Typography>
                      <Typography variant="bodySmall">
                        {
                          [
                            "(Less 1 Full Shelve)",
                            "(1 Full Shelve)",
                            "(1.5 Full Shelve)",
                            "(2 Full Shelve)",
                          ][index]
                        }
                      </Typography>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {planogramData && (
          <div className={globalstyles.innerContainer}>
            <ContainerTitle title={locale.Planogram} icon={ShelfIcon} />
            <div className={classes.complianceContainer}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {locale.PlanogramCompliance}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  margin: "0.313rem 0",
                }}
              >
                <img
                  src={planogramData?.status == 0 ? RedEmoji : GreenEmoji}
                  alt={
                    planogramData?.status == 0
                      ? "Poor Compliance"
                      : "Good Compliance"
                  }
                  style={{ width: "2.5rem" }}
                />
                <Typography
                  variant="h1"
                  style={{
                    marginLeft: "0.5rem",
                    color:
                      planogramData?.status == 0
                        ? theme.palette.error[300]
                        : theme.palette.success[300],
                  }}
                >
                  {planogramData?.status == 0
                    ? locale.Poor
                    : planogramData?.status == 1
                    ? locale.Good
                    : planogramData?.status == 2
                    ? locale.Better
                    : locale.Best}
                </Typography>
              </div>
              {planogramImageSrc && dimensions && (
                <img
                  src={planogramImageSrc}
                  alt="Shelf Compliance"
                  style={{
                    width: `${dimensions.width}px`,
                    // height: `${dimensions.height}px`,
                    marginTop: "0.5rem",
                  }}
                />
              )}
            </div>
          </div>
        )}

        {POSMdata && Array.isArray(POSMdata.units) && (
          <div className={globalstyles.innerContainer}>
            <ContainerTitle
              title="POSM"
              icon={CouponIcon}
              images={[
                images[imgIndex][1][0],
                images[imgIndex][3][0],
                images[imgIndex][1][1],
                images[imgIndex][3][1],
                images[imgIndex][1][2],
                images[imgIndex][3][2],
              ]}
            />
            <div className={classes.complianceContainer}>
              <Typography
                variant="titleSmall"
                style={{ color: theme.palette.decorative.darkblue }}
              >
                {POSMdata?.name}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color:
                      POSMdata?.pass < POSMdata?.total
                        ? theme.palette.error[300]
                        : theme.palette.decorative.darkblue,
                  }}
                >
                  {POSMdata?.pass}
                </Typography>
                <Typography
                  variant="h1"
                  style={{ color: theme.palette.neutral[700] }}
                >
                  /{POSMdata?.total}
                </Typography>
              </div>
              <div className={globalstyles.greyContainer} style={{ margin: 0 }}>
                {POSMdata.units.map((unit, index) => (
                  <div
                    style={{
                      padding: "0.5rem",
                      backgroundColor: unit.availability
                        ? theme.palette.success[50]
                        : theme.palette.error[50],
                    }}
                    key={`posm-units-${index}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Typography variant="titleSmall">
                          {unit.name}
                        </Typography>
                      </div>
                      <div style={{ flexShrink: 0 }}>
                        <AvailabilityComponent unit={unit} locale={locale} />
                      </div>
                    </div>
                    {unit.img && (
                      <center>
                        <img
                          src={unit.img}
                          alt={`POSM Unit - ${unit.name}`}
                          style={{
                            maxWidth: "11.25rem",
                            margin: "1.25rem 0",
                            borderRadius: "0.375rem",
                          }}
                        />
                      </center>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {skuData && POSMdata && (
          <div className={globalstyles.innerContainer}>
            <ContainerTitle
              title={locale.Pricing}
              icon={MoneyIcon}
              images={[images[0][1], images[0][3]]}
            />
            <Box sx={{ width: "100%", margin: "1rem 0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {tabData.map((tab, index) => (
                  <Tab
                    key={index}
                    sx={{
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: `${theme.palette.primary[400]} !important`, // Selected tab color
                      },
                    }}
                    label={
                      <Typography variant="titleSmall" component="span">
                        {tab.category}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
              <SwipeableViews
                axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleSwipeChange}
              >
                {tabData.map((tab, contentIndex) => (
                  <TabPanel
                    key={contentIndex}
                    value={value}
                    index={contentIndex}
                    theme2={theme2}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                backgroundColor: theme.palette.neutral[200],
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{
                                  color: theme.palette.neutral[400],
                                  textAlign: "center",
                                }}
                              >
                                {locale.Product}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                backgroundColor: theme.palette.neutral[200],
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{
                                  color: theme.palette.neutral[400],
                                  textAlign: "center",
                                }}
                              >
                                {locale.DetectedPrice}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                backgroundColor: theme.palette.neutral[200],
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{
                                  color: theme.palette.neutral[400],
                                  textAlign: "center",
                                }}
                              >
                                {locale.ActualPrice}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tab.content &&
                            tab.content.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="bodySmall"
                                      style={{
                                        color: theme.palette.neutral[700],
                                      }}
                                    >
                                      {row.product}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <Typography
                                    variant="titleSmall"
                                    style={{
                                      color: theme.palette.primary[400],
                                    }}
                                  >
                                    {row.price}
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <TextField
                                    size="small"
                                    type="number"
                                    slotProps={{
                                      input: {
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            RM
                                          </InputAdornment>
                                        ),
                                      },
                                    }}
                                    onChange={(event) => {
                                      let formattedValue = parseFloat(
                                        event.target.value
                                      ).toFixed(2);
                                      setPrice({
                                        index: index,
                                        value: formattedValue,
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Box>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ViewCapture;
